import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Routes } from '@angular/router';
import { AcsGuard } from '@konnektu/acs-helpers';
import { tenantAuthGuard } from '@konnektu/auth';
import {
  AbTestDto,
  AbTestPartWithTemplate,
  AbTestService
} from '@konnektu/communication/ab-tests/data';
import {
  CommunicationApiService,
  CommunicationMessageDto
} from '@konnektu/communication/api';
import { GigachatService } from '@konnektu/gigachat/data';
import { createContextLogger } from '@konnektu/helpers';
import { MARKETING_CAMPAIGN_ROUTES } from '@konnektu/marketing-campaigns/module';
import { MetastoreService, equal, from } from '@konnektu/metastore';
import { LayoutComponent } from '@konnektu/sidebar-layout';
import { NotFoundPageComponent } from '@konnektu/status-page';
import { format } from 'date-fns';
import { forkJoin, map, of, switchMap } from 'rxjs';
import { useTenantExtensions } from './extensions/tenant-extensions';
const logger = createContextLogger('createTenantRouting');

export async function createTenantRouting(tenantCode: string): Promise<Routes> {
  const extensionRouteConfig = await useTenantExtensions(tenantCode);
  logger.debug('Built extensions router config', extensionRouteConfig);
  return [
    {
      path: tenantCode,
      children: [
        {
          path: '404',
          component: NotFoundPageComponent
        },
        {
          path: 'cabinet-response',
          loadComponent: () =>
            import('@konnektu/segments/export').then(
              (m) => m.OauthRedirectPageComponent
            )
        },
        {
          path: 'login',
          loadComponent: () =>
            import('@konnektu/login-page').then((l) => l.LoginPageComponent)
        },
        {
          path: '',
          canActivate: [tenantAuthGuard(tenantCode), AcsGuard],
          canActivateChild: [tenantAuthGuard(tenantCode), AcsGuard],
          children: [
            {
              path: '',
              redirectTo: 'dashboard',
              pathMatch: 'full'
            },
            {
              path: '',
              component: LayoutComponent,
              data: {
                breadcrumb: 'breadcrumbs.main'
              },
              children: [
                ...extensionRouteConfig,
                {
                  path: 'segments',
                  loadChildren: () =>
                    import('@konnektu/segments-view').then(
                      (m) => m.SegmentsModule
                    )
                },
                {
                  path: 'pixels',
                  loadChildren: () =>
                    import('@konnektu/pixel/view').then((m) => m.PIXEL_ROUTES)
                },
                {
                  path: 'cabinets',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.advertisingCampaigns.title',
                      link: null
                    }
                  },
                  children: [
                    {
                      path: '',
                      loadComponent: () =>
                        import('@konnektu/segments/export').then(
                          (c) => c.CabinetSectionComponent
                        ),
                      data: {
                        breadcrumb: 'menu.entry.advertisingCampaigns.cabinets'
                      }
                    },
                    {
                      path: 'jobs',
                      loadComponent: () =>
                        import('@konnektu/segments/export').then(
                          (c) => c.ExportJobSectionComponent
                        ),
                      data: {
                        breadcrumb: 'menu.entry.advertisingCampaigns.media'
                      }
                    }
                  ]
                },
                {
                  path: 'calendar',
                  loadChildren: () =>
                    import('@konnektu/calendar').then((m) => m.CalendarModule)
                },
                {
                  path: 'communications',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.communication.title',
                      link: null
                    }
                  },
                  children: [
                    {
                      path: 'html-fragments',
                      data: {
                        breadcrumb:
                          'menu.entry.communication.fragments.menuLabel'
                      },
                      loadChildren: () =>
                        import('@konnektu/communication/html-fragments').then(
                          (m) => m.HTML_FRAGMENT_ROUTES
                        )
                    },
                    {
                      path: '',
                      pathMatch: 'full',
                      redirectTo: 'templates'
                    },
                    {
                      path: 'templates',
                      data: {
                        breadcrumb:
                          'menu.entry.communication.templates.menuLabel'
                      },
                      children: [
                        {
                          path: '',
                          loadComponent: () =>
                            import('@konnektu/communication/section').then(
                              (c) => c.CommunicationSectionComponent
                            ),
                          data: {
                            breadcrumb: null
                          }
                        },
                        {
                          path: ':communicationId',
                          resolve: {
                            communication: (route: ActivatedRouteSnapshot) => {
                              const metastore = inject(MetastoreService);
                              const id = parseInt(
                                route.paramMap.get('communicationId') as string,
                                10
                              );
                              return metastore.find(
                                from('Communication')
                                  .select(['Name'])
                                  .where(equal('Id', id))
                              );
                            }
                          },
                          data: {
                            breadcrumb: (data: {
                              communication: { Name: string };
                            }) => data.communication.Name
                          },
                          children: [
                            {
                              path: '',
                              data: {
                                breadcrumb: null
                              },
                              loadComponent: () =>
                                import('@konnektu/communication/card').then(
                                  (c) => c.CommunicationCardComponent
                                )
                            },
                            {
                              path: 'messages',
                              data: {
                                breadcrumb: {
                                  label: 'communicationMessages.breadcrumb',
                                  link: null
                                }
                              },
                              children: [
                                {
                                  path: ':messageId',
                                  resolve: {
                                    message: (
                                      route: ActivatedRouteSnapshot
                                    ) => {
                                      const communicationService = inject(
                                        CommunicationApiService
                                      );
                                      const id = parseInt(
                                        route.paramMap.get(
                                          'messageId'
                                        ) as string,
                                        10
                                      );
                                      return communicationService.getMessage(
                                        id
                                      );
                                    }
                                  },
                                  data: {
                                    breadcrumb: (data: {
                                      message: CommunicationMessageDto;
                                    }) =>
                                      format(
                                        new Date(data.message.startedAt),
                                        'dd.MM.yyyy HH:mm'
                                      )
                                  },
                                  loadComponent: () =>
                                    import(
                                      '@konnektu/communication/message-card'
                                    ).then((c) => c.MessageCardComponent)
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'ab-tests',
                      data: {
                        breadcrumb: 'menu.entry.communication.abTests.menuLabel'
                      },
                      children: [
                        {
                          path: '',
                          data: {
                            breadcrumb: null
                          },
                          loadComponent: () =>
                            import(
                              '@konnektu/communication/ab-tests/view'
                            ).then((c) => c.AbTestSectionComponent)
                        },
                        {
                          path: ':id',
                          data: {
                            breadcrumb: null
                          },
                          resolve: {
                            abTest: (route: ActivatedRouteSnapshot) => {
                              const abTestService = inject(AbTestService);
                              const communicationService = inject(
                                CommunicationApiService
                              );
                              const id = parseInt(
                                route.paramMap.get('id') as string,
                                10
                              );
                              return abTestService.getAbTest(id).pipe(
                                switchMap((abTestDto) => {
                                  if (!abTestDto.parts.length) {
                                    return of(abTestDto);
                                  }
                                  return forkJoin(
                                    abTestDto.parts.map((p) =>
                                      communicationService.getTemplate(
                                        p.templateId
                                      )
                                    )
                                  ).pipe(
                                    map((res) => {
                                      abTestDto.parts = abTestDto.parts.map(
                                        (p) => ({
                                          ...p,
                                          template: res.find(
                                            (t) => t.id === p.templateId
                                          )
                                        })
                                      );
                                      return abTestDto as AbTestDto & {
                                        parts: AbTestPartWithTemplate[];
                                      };
                                    })
                                  );
                                })
                              );
                            },
                            abTestRuns: (route: ActivatedRouteSnapshot) => {
                              const abTestService = inject(AbTestService);
                              const id = parseInt(
                                route.paramMap.get('id') as string,
                                10
                              );
                              return abTestService.getAbTestRuns(id);
                            }
                          },
                          loadComponent: () =>
                            import(
                              '@konnektu/communication/ab-tests/view'
                            ).then((c) => c.AbTestCardComponent)
                        }
                      ]
                    },
                    {
                      path: ':id',
                      redirectTo: 'templates/:id'
                    }
                  ]
                },
                {
                  path: 'yandex-gpt',
                  data: {
                    breadcrumb: 'menu.entry.gpt.title'
                  },
                  loadComponent: () =>
                    import('@konnektu/yandex-gpt/view').then(
                      (c) => c.YandexGptChatComponent
                    )
                },
                {
                  path: 'gigachat',
                  data: {
                    breadcrumb: 'menu.entry.gigachat.title'
                  },
                  canActivate: [
                    () => {
                      const router = inject(Router);
                      return inject(GigachatService).isGigachatEnabled.pipe(
                        map(
                          (enabled) =>
                            enabled || router.createUrlTree([`/${tenantCode}`])
                        )
                      );
                    }
                  ],
                  loadComponent: () =>
                    import('@konnektu/gigachat/view').then(
                      (c) => c.GigachatChatComponent
                    )
                },
                {
                  path: 'gigachat',
                  loadComponent: () =>
                    import('@konnektu/gigachat/view').then(
                      (c) => c.GigachatChatComponent
                    )
                },
                {
                  path: 'file-moderation',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.marketingCampaigns.title',
                      link: null
                    }
                  },
                  loadChildren: () =>
                    import(
                      './modules/file-moderation/file-moderation-routing.module'
                    ).then((cm) => cm.FILE_MODERATION_ROUTES)
                },
                {
                  path: 'loyalty-promos',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.marketingCampaigns.title',
                      link: null
                    }
                  },
                  loadChildren: () =>
                    import(
                      './modules/loyalty-promos/loyalty-promos.module'
                    ).then((m) => m.LoyaltyPromosModule)
                },
                {
                  path: 'campaigns',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.marketingCampaigns.title',
                      link: null
                    }
                  },
                  loadChildren: () =>
                    import('./modules/campaigns/campaigns.module').then(
                      (m) => m.CampaignsModule
                    )
                },
                {
                  path: 'campaign-schemas',
                  loadChildren: () =>
                    import('@konnektu/campaign-schema').then(
                      (m) => m.CampaignSchemaModule
                    )
                },
                ...MARKETING_CAMPAIGN_ROUTES,
                {
                  path: 'methods',
                  loadChildren: () =>
                    import('./modules/methods/methods.module').then(
                      (m) => m.MethodsModule
                    )
                },
                {
                  path: 'method-sources',
                  loadChildren: () =>
                    import('./modules/methods/method-sources.module').then(
                      (m) => m.MethodSourcesModule
                    )
                },
                {
                  path: 'users',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.database.title',
                      link: null
                    }
                  },
                  loadChildren: () =>
                    import('./modules/user/user.module').then(
                      (m) => m.UserModule
                    )
                },
                {
                  path: 'anonymous-users',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.database.title',
                      link: null
                    }
                  },
                  loadChildren: () =>
                    import(
                      './modules/anonymous-users/anonymous-users.module'
                    ).then((m) => m.AnonymousUsersModule)
                },
                {
                  path: 'receipts',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.marketingCampaigns.title',
                      link: null
                    }
                  },
                  loadChildren: () =>
                    import('./modules/receipts/receipts.module').then(
                      (m) => m.ReceiptsModule
                    )
                },
                {
                  path: 'questionnaires',
                  loadChildren: () =>
                    import('./modules/questionnaire/questionnaire.module').then(
                      (m) => m.QuestionnaireModule
                    )
                },
                {
                  path: 'coupons',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.marketingCampaigns.title',
                      link: null
                    }
                  },
                  loadChildren: () =>
                    import('./modules/coupons/coupons.module').then(
                      (m) => m.CouponsModule
                    )
                },
                {
                  path: 'lookups',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.administration',
                      link: null
                    }
                  },
                  loadChildren: () =>
                    import('./modules/lookups/lookups.module').then(
                      (m) => m.LookupsModule
                    )
                },
                {
                  path: 'dashboard',
                  loadChildren: () =>
                    import('./modules/dashboard/dashboard.module').then(
                      (m) => m.DashboardModule
                    )
                },
                {
                  path: 'analytics',
                  loadChildren: () =>
                    import('@konnektu/analytics-view').then(
                      (a) => a.AnalyticsViewRoutingModule
                    )
                },
                {
                  path: 'dashboard-settings',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.administration',
                      link: null
                    }
                  },
                  children: [
                    {
                      path: '',
                      data: { breadcrumb: 'SupersetDashboards' },
                      loadComponent: () =>
                        import('@konnektu/analytics-view').then(
                          (c) => c.SupersetDashboardSettingsComponent
                        )
                    }
                  ]
                },
                {
                  path: 'acs',
                  data: {
                    breadcrumb: {
                      label: 'menu.entry.administration',
                      link: null
                    }
                  },
                  loadChildren: () =>
                    import('@konnektu/acs-view').then((m) => m.AcsViewModule)
                },
                {
                  path: '**',
                  redirectTo: 'not-found'
                }
              ]
            }
          ]
        }
      ]
    }
  ];
}
