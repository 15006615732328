import { SidenavItem } from '@konnektu/sidebar-layout';

const MARKETING_CAMPAIGNS_TENANTS = [
  'khl',
  'unilever',
  'staging',
  'astrazeneca',
  'cdpdemo',
  'akbars',
  'admiral',
  'amur',
  'avangard',
  'barys',
  'cska',
  'dynamoomn',
  'lokomotiv',
  'metallurg',
  'neftekhimik',
  'sibir',
  'severstal',
  'spartak',
  'torpedo',
  'traktor',
  'vityaz',
  'internal'
];

export function createMenuItems(
  tenantCode: string,
  dashboards: { Id: number; Name: string }[],
  isGigachatEnabled = false
): SidenavItem[] {
  const menuItems: SidenavItem[] = [
    {
      id: 'dashboard',
      label: 'menu.entry.dashboard.title',
      icon: 'knkTech' as const,
      children: dashboards.map((dashboard) => ({
        id: `dashboard-${dashboard.Id}`,
        label: dashboard.Name,
        route: `/${tenantCode}/analytics/dashboard/${dashboard.Id}`,
        icon: 'knkList'
      }))
    },
    {
      id: 'integrations',
      label: 'menu.entry.integrations.title',
      icon: 'knkList' as const,
      children: [
        {
          id: 'methods',
          label: 'menu.entry.integrations.methods',
          route: `/${tenantCode}/methods`,
          icon: 'knkList' as const
        },
        {
          id: 'methods/in-source',
          label: 'menu.entry.integrations.methodsInSource',
          route: `/${tenantCode}/method-sources`,
          icon: 'knkList' as const
        }
      ]
    },
    {
      id: 'data-base',
      label: 'menu.entry.database.title',
      icon: 'knkCustomerCard' as const,
      children: [
        {
          id: 'anonymousUsers',
          label: 'menu.entry.database.anonymousUsers',
          route: `/${tenantCode}/anonymous-users`,
          icon: 'knkCustomerCard' as const
        },
        {
          id: 'users',
          label: 'menu.entry.database.users',
          icon: 'knkCustomerCard' as const,
          route: `/${tenantCode}/users`
        }
      ]
    },
    {
      id: 'marketingCampaigns',
      label: 'menu.entry.marketingCampaigns.title',
      icon: 'knkChoose' as const,
      children: [
        {
          id: 'campaigns',
          label: 'menu.entry.marketingCampaigns.campaigns',
          icon: 'knkList' as const,
          route: `/${tenantCode}/campaigns`
        },
        {
          id: 'receipts',
          label: 'menu.entry.receipts',
          icon: 'knkCheck' as const,
          route: `/${tenantCode}/receipts`
        },
        {
          id: 'coupons',
          label: 'menu.entry.coupons',
          icon: 'knkCertificate' as const,
          children: [
            {
              id: 'section',
              label: 'menu.entry.coupons',
              route: `/${tenantCode}/coupons`,
              icon: 'knkList' as const
            },
            {
              id: 'coupon-generators',
              label: 'menu.entry.couponGenerators',
              route: `/${tenantCode}/coupons/generators`,
              icon: 'knkWait' as const
            }
          ]
        },
        {
          id: 'loyalty-programs',
          label: 'menu.entry.loyaltyPrograms',
          route: `/${tenantCode}/loyalty-promos`,
          icon: 'knkList' as const
        },
        {
          id: 'file-moderation',
          label: 'menu.entry.fileModeration.title',
          icon: 'knkList' as const,
          route: `/${tenantCode}/file-moderation`
        }
      ]
    },
    {
      id: 'advertisingCampaigns',
      label: 'menu.entry.advertisingCampaigns.title',
      icon: 'knkList' as const,
      children: [
        {
          id: 'cabinets',
          label: 'menu.entry.advertisingCampaigns.cabinets',
          route: `/${tenantCode}/cabinets`,
          icon: 'knkList' as const
        },
        {
          id: 'jobs',
          label: 'menu.entry.advertisingCampaigns.media',
          route: `/${tenantCode}/cabinets/jobs`,
          icon: 'knkList' as const
        }
      ]
    },
    ...(MARKETING_CAMPAIGNS_TENANTS.includes(tenantCode)
      ? [
          {
            id: 'marketingCampaignSchemas',
            label: 'menu.entry.marketingCampaignSchemas',
            route: `/${tenantCode}/marketing-campaigns`,
            icon: 'knkList' as const
          }
        ]
      : []),
    {
      id: 'pixels',
      label: 'menu.entry.pixels.title',
      route: `/${tenantCode}/pixels`,
      icon: 'knkList' as const
    },
    {
      id: 'schemas',
      label: 'menu.entry.marketingCampaigns.campaignSchemas',
      route: `/${tenantCode}/campaign-schemas`,
      icon: 'knkFlow' as const
    },
    {
      id: 'segments',
      label: 'menu.entry.segments.title',
      icon: 'knkSegments' as const,
      route: `/${tenantCode}/segments`
    },
    {
      id: 'communications',
      label: 'menu.entry.communication.title',
      icon: 'knkCommunication' as const,
      children: [
        {
          id: 'templates',
          icon: 'knkCommunication',
          label: 'menu.entry.communication.templates.menuLabel',
          route: `/${tenantCode}/communications/templates`
        },
        {
          id: 'ab-tests',
          icon: 'knkCommunication',
          label: 'menu.entry.communication.abTests.menuLabel',
          route: `/${tenantCode}/communications/ab-tests`
        },
        {
          id: 'html-fragments',
          icon: 'knkCommunication',
          label: 'menu.entry.communication.fragments.menuLabel',
          route: `/${tenantCode}/communications/html-fragments`
        }
      ]
    },
    ...(isGigachatEnabled
      ? [
          {
            id: 'gigachat',
            label: 'menu.entry.gigachat.title',
            icon: 'knkAi' as const,
            route: `/${tenantCode}/gigachat`
          }
        ]
      : []),
    {
      id: 'yandex-gpt',
      label: 'menu.entry.gpt.title',
      icon: 'knkAi' as const,
      route: `/${tenantCode}/yandex-gpt`
    },

    {
      id: 'calendar',
      label: 'menu.entry.calendar',
      route: `/${tenantCode}/calendar`,
      icon: 'knkClock' as const
    },
    {
      id: 'administration',
      label: 'menu.entry.administration',
      icon: 'knkSettingsVertical' as const,
      children: [
        {
          id: 'roles',
          label: 'menu.entry.roles',
          route: `/${tenantCode}/acs/roles`,
          icon: 'knkExtension' as const
        },
        {
          id: 'operator-roles',
          label: 'menu.entry.operatorRoles',
          route: `/${tenantCode}/acs/applied-roles`,
          icon: 'knkExtension' as const
        },
        {
          id: 'lookups',
          label: 'menu.entry.lookups',
          icon: 'knkSearch' as const,
          children: [
            {
              id: 'user-type',
              label: 'menu.entry.lookupsUserType',
              route: `/${tenantCode}/lookups/user-type`,
              icon: 'knkSearch' as const
            },
            {
              id: 'user-status',
              label: 'menu.entry.lookupsUserStatus',
              route: `/${tenantCode}/lookups/user-status`,
              icon: 'knkSearch' as const
            },
            {
              id: 'identifier-type',
              label: 'menu.entry.lookupsIdentifierType',
              route: `/${tenantCode}/lookups/identifier-types`,
              icon: 'knkSearch' as const
            },
            {
              id: 'unsubscribe',
              label: 'menu.entry.lookupsUnsubscribe',
              route: `/${tenantCode}/lookups/unsubscribe`,
              icon: 'knkSearch' as const
            },
            {
              id: 'ticket-generator',
              label: 'menu.entry.ticketGenerators',
              route: `/${tenantCode}/lookups/ticket-generators`,
              icon: 'knkSearch' as const
            },
            {
              id: 'bonus-wallet',
              label: 'menu.entry.lookupsBonusWallet',
              route: `/${tenantCode}/lookups/bonus-wallet`,
              icon: 'knkSearch' as const
            },
            {
              id: 'custom-event',
              label: 'menu.entry.lookupsCustomEvents',
              route: `/${tenantCode}/lookups/custom-event`,
              icon: 'knkSearch' as const
            },
            {
              id: 'receipt-status',
              label: 'menu.entry.lookupsReceiptStatus',
              route: `/${tenantCode}/lookups/receipt-status`,
              icon: 'knkSearch' as const
            }
          ]
        },
        {
          id: `superset-dashboard-settings`,
          label: 'SupersetDashboards',
          route: `/${tenantCode}/dashboard-settings`,
          icon: 'knkList'
        }
      ]
    }
  ];

  return menuItems;
}
